<template>
  <div class="row mt-3">
    <form v-on:submit.prevent="" class="col-12">
      <div class="form-row mb-4">
        <label class="col small font-weight-bold bmd-label-static">Afficher la zone des emojis (pour vos réglages)</label>
        <div class="mr-4 switch" data-toggle="tooltip" title="" data-original-title="Activer / Désactiver la zone des emojis">
          <label style="position: relative; left: 5px"> <input v-model="EmojisConfig.displayZone" id="toggle-zone" type="checkbox"><span class="bmd-switch-track"></span></label>
        </div>
      </div>
      <div class="form-group mt-4">
        <div class="row">
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="height">Hauteur de la zone <b>(en %)</b></label>
            <input v-model="EmojisConfig.drawingZone.height" id="height" type="number" class="form-control" placeholder="100">
          </div>
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="width">Largeur de la zone <b>(en %)</b></label>
            <input v-model="EmojisConfig.drawingZone.width" id="width" type="number" class="form-control" placeholder="100">
          </div>
        </div>
      </div>
      <div class="form-group mt-4">
        <div class="row">
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="posX">Position de la zone X <b>(en %)</b></label>
            <input v-model="EmojisConfig.drawingZone.x" id="posX" type="number" class="form-control" placeholder="0">
          </div>
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="posY">Position de la zone Y <b>(en %)</b></label>
            <input v-model="EmojisConfig.drawingZone.y" id="posY" type="number" class="form-control" placeholder="0">
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="small font-weight-bold bmd-label-static" for="drawingMethod">Technique de rendu</label>
        <select class="form-control" v-model="EmojisConfig.drawingMethod" id="drawingMethod">
          <option value="svg" >SVG (CPU)</option>
          <option value="canvas" >Canvas (CPU)</option>
          <option value="webGl" >WebGL (GPU)</option>
        </select>
      </div>
      <div class="form-group">
        <label class="small font-weight-bold bmd-label-static" for="direction">Direction des Emojis</label>
        <select class="form-control" v-model="EmojisConfig.direction" id="direction">
          <option value="left" >Gauche</option>
          <option value="right" >Droite</option>
          <option value="up" >Haut</option>
          <option value="down" >Bas</option>
        </select>
      </div>
      <div class="form-group mt-4">
        <div class="row">
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="size">Taille des Emojis <b>(en px)</b></label>
            <input v-model="EmojisConfig.size" id="size" type="number" class="form-control" placeholder="0">
          </div>
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="scale">Scale <b>(en %)</b></label>
            <input v-model="EmojisConfig.scale" id="scale" type="number" class="form-control" placeholder="0">
          </div>
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="scaleRandomness">Aleatoire <b>(en %)</b></label>
            <input v-model="EmojisConfig.scaleRandomness" id="scaleRandomness" type="number" class="form-control" placeholder="0">
          </div>
        </div>
      </div>
      <div class="form-group mt-4">
        <div class="row">
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="size">Vitesse des Emojis</label>
            <input v-model="EmojisConfig.speed" id="speed" type="number" class="form-control" placeholder="0">
          </div>
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="scale">Aleatoire <b>(en %)</b></label>
            <input v-model="EmojisConfig.speedRandomness" id="speedRandomness" type="number" class="form-control" placeholder="0">
          </div>
        </div>
      </div>
      <div class="form-group mt-4">
        <div class="row">
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="amplitude">Amplitude des Emojis</label>
            <input v-model="EmojisConfig.amplitude" id="amplitude" type="number" class="form-control" placeholder="0">
          </div>
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="scale">Aleatoire <b>(en %)</b></label>
            <input v-model="EmojisConfig.amplitudeRandomness" id="amplitudeRandomness" type="number" class="form-control" placeholder="0">
          </div>
        </div>
      </div>
      <div class="form-group mt-4">
        <div class="row">
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="frequency">Oscillation des Emojis</label>
            <input v-model="EmojisConfig.frequency" id="frequency" type="number" class="form-control" placeholder="0">
          </div>
          <div class="col">
            <label class="small font-weight-bold bmd-label-static" for="frequencyRandomness">Aleatoire <b>(en %)</b></label>
            <input v-model="EmojisConfig.frequencyRandomness" id="frequencyRandomness" type="number" class="form-control" placeholder="0">
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="small font-weight-bold bmd-label-static" for="maxEmojis">Nombre d'emojis maximum la fois</label>
        <input v-model="EmojisConfig.maxDisplayed" id="maxEmojis" type="number" class="form-control" placeholder="35">
      </div>
    </form>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import VueNotifications from 'vue-notifications';

export default {
  name: 'EmojisLeftBar.vue',
  computed: {
    ...mapState(['EmojisConfig']),
  },
  methods: {
    notif()
    {
      this.restartNotif();
    },
  },
  notifications: {
    restartNotif: {
      type: VueNotifications.types.warn,
      title: 'Veuillez recharger la page',
      message: 'Il est possible que la configuration appliquée nécessite un rechargement de la page.',
    },
  },
};

</script>

<style scoped>

</style>
