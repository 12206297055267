/**
 * TODO DOC
 */
const HideConfig = {
  backgroundImage: 'https://captag-interactions.web.app/assets/hideDefault.png',
  backgroundColor: {
    rgba: {
      r: 0,
      g: 0,
      b: 0,
      a: 255,
    },
  },
};

export default HideConfig;
