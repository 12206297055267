<template>
  <div class="modal fade" id="AddFontModal" tabindex="-1" role="dialog" aria-labelledby="AddFontModalTitle" aria-hidden="true">
    <div class="h-75 modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="AddFontModalTitle">Gérer mes polices</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div id="FontModalAccordion">
            <AccordionCard title="Gérer les polices importées" id="manage" accordion-name="FontModalAccordion" v-bind:expanded="Object.keys(GoogleFonts).length <= 0 ? 'false' : 'true'">
              <p class="text-center" v-if="Object.keys(GoogleFonts).length <= 0">Aucune police importé pour le moment</p>
              <ul class="list-group bmd-list-group-sm">
                <a v-for="(font, key) in GoogleFonts" v-bind:key="key" class="list-group-item">
                  <div class="bmd-list-group-col">
                    <p class="list-group-item-heading">{{ font.name }}</p>
                    <p class="list-group-item-text">{{ font.source }}</p>
                  </div>
                  <button v-on:click="removeFont(key)" class="btn btn-danger btn-raised pull-xs-right"><i class="mr-0 fa fa-trash"></i></button>
                </a>
              </ul>
            </AccordionCard>
            <AccordionCard title="Ajouter une police" id="addFont" accordion-name="FontModalAccordion" v-bind:expanded="Object.keys(GoogleFonts).length <= 0 ? 'true' : 'false'">
              <div class="row">
                <form v-on:submit.prevent class="col-12">
                  <div class="form-group">
                    <label class="small font-weight-bold bmd-label-static" for="fontName">Nom de la police</label>
                    <input v-model="FontData.name" id="fontName" type="text" class="form-control">
                  </div>
                  <div class="form-group">
                    <label class="small font-weight-bold bmd-label-static" for="FontSource">Source</label>
                    <select v-model="FontData.source" id="FontSource" class="form-control">
                      <option value="GoogleFont">Google font</option>
                    </select>
                  </div>
                  <div v-if="FontData.source === 'GoogleFont'" class="form-group">
                    <label class="small font-weight-bold bmd-label-static" for="fontWeight">Poids de la police</label>
                    <multiselect id="fontWeight" v-model="FontData.FontWeight" tag-placeholder="Ajouter ce poids" placeholder="Selectionnez ou tapez un poids" label="name" track-by="size" :options="FontWeightOptions" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                  </div>
                  <div class="form-group text-center mb-0">
                    <button v-on:submit.prevent="" data-dismiss="modal" v-on:click="submitFont" class="btn btn-raised btn-primary ">Ajouter cette police</button>
                  </div>
                </form>
              </div>
            </AccordionCard>
            </div>
          </div>
        <div class="modal-footer">
          <button data-dismiss="modal" aria-label="Save" type="button" class="btn btn-primary">fermer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

/**
 * TODO REVOIR CET MODAL
 */

import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import { uuid } from 'vue-uuid';
import VueNotifications from 'vue-notifications';
import AccordionCard from '@/components/AccordionCard.vue';

export default {
  name: 'AddFontModal',
  components: { Multiselect, AccordionCard },
  data() {
    return {
      FontData: {
        name: '',
        source: 'GoogleFont',
        FontWeight: [
          { name: 'Regular 400', size: '0,400' },
        ],
      },
      FontWeightOptions: [
        { name: 'Thin 100', size: '0,100' },
        { name: 'Thin 100 italic', size: '1,100' },
        { name: 'Light 300', size: '0,300' },
        { name: 'Light 300 italic', size: '1,300' },
        { name: 'Regular 400', size: '0,400' },
        { name: 'Regular 400 italic', size: '1,400' },
        { name: 'Medium 500', size: '0,500' },
        { name: 'Medium 500 italic', size: '1,500' },
        { name: 'Bold 700', size: '0,700' },
        { name: 'Bold 700 italic', size: '1,700' },
        { name: 'Black 900', size: '0,900' },
        { name: 'Black 900 italic', size: '1,900' },
      ],
    };
  },
  computed: {
    ...mapState(['GoogleFonts']),
  },
  methods: {
    removeFont(font)
    {
      this.$store.dispatch('removeConfig', {
        endpoint: `screenConfig/fonts/${font}`,
      }).then(this.fontRemoveNotif());
    },
    computeGoogleFontURL()
    {
      // TODO FONT WEIGHT HERE
      const fontWeight = '400';
      return `https://fonts.googleapis.com/css2?family=${this.FontData.name.replace(' ', '+')}:wght@${fontWeight}&display=swap`;
    },
    submitFont()
    {
      if (this.FontData.source === 'GoogleFont')
        this.FontData.GoogleFontURL = this.computeGoogleFontURL();
      else
        this.FontData.FontWeight = {};
      this.$store.dispatch('updateConfig', {
        endpoint: `screenConfig/fonts/${uuid.v4()}`,
        object: this.FontData,
      }).then(this.fontSaveNotif());
    },
    addTag(newTag)
    {
      const tag = {
        name: newTag,
        size: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)), // i have no idea what's going on here
      };
      this.options.push(tag);
      this.value.push(tag);
    },
  },
  notifications: {
    fontSaveNotif: {
      type: VueNotifications.types.success,
      title: 'Police Ajouté',
      message: 'La police a bien été ajouté au registre !<br>Vous pouvez maintenant l\'utiliser dans le menu latéral.',
    },
    fontRemoveNotif: {
      type: VueNotifications.types.warn,
      title: 'Police Supprimée',
      message: 'La police a bien été enlevée du registre !',
    },
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

</style>
