<template>
  <div class="h-100 d-flex justify-content-center align-items-center align-middle">
    <div class="row w-100 text-center">
      <div class="col-12">
        <h1 style="font-size: 70px;">Interactions live</h1>
      </div>
      <div class="col-12 mb-5">
        <h5 style="font-size: 30px;">Chargement en cours</h5>
      </div>
      <div class="col-12 mt-5 mb-3 d-flex justify-content-center align-items-center align-middle">
        <breeding-rhombus-spinner
          :animation-duration="2000"
          :size="150"
          color="#FAA725"
        />
      </div>
      <div v-if="this.showTimeoutMessage" class="col-12 mt-5">
        <h5 style="font-size: 30px; color: #FF341E;">Chargement anormalement long<br>Rechargez votre page et verifiez votre connection internet.</h5>
      </div>
    </div>
  </div>
</template>

<script>

import { BreedingRhombusSpinner } from 'epic-spinners';

export default {
  name: 'LoadingPage',
  components: {
    BreedingRhombusSpinner,
  },
  data() {
    return {
      showTimeoutMessage: false,
    };
  },
  created()
  {
    setInterval(() => { this.showTimeoutMessage = true; }, 5000);
  },
};

</script>

<style scoped>

</style>
