import VueNotifications from 'vue-notifications';
import Noty from 'noty';
import Vue from 'vue';
import LoadScript from 'vue-plugin-load-script';
import App from './App.vue';
import store from './store';
import 'noty/lib/noty.css';
import 'noty/lib/themes/sunset.css';

store.dispatch('loadUrlParams');

Vue.config.productionTip = false;

function toast({
  title,
  message,
  type,
  timeout,
  cb,
}) {
  if (type === VueNotifications.types.warn)
    type = 'warning';
  return new Noty({
    theme: 'sunset',
    text: `<h5 style="color: #ffffff;">${title}</h5><p>${message}</p>`,
    timeout,
    type,
    layout: 'bottomRight',
  }).show();
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

Vue.use(LoadScript);
Vue.use(VueNotifications, options);

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
