<template>
  <div class="card">
    <div class="card-header text-white bg-dark" v-bind:id="`${id}Header`">
      <div class="row justify-content-between">
        <div class="col ">
          <h5 class="mt-1">{{ title }}</h5>
        </div>
        <div v-if="active" class="col-4 align-self-center">
          <div style="background-color: #FAA725; border-radius: 3px;" class="text-center">
            <i class="fa fa-play"></i> En cours
          </div>
        </div>
        <button data-toggle="collapse" v-bind:data-target="`#${id}`" type="button" class="collapsed col-2 btn btn-primary" v-bind:aria-expanded="expanded" v-bind:aria-controls="id">
          <i class="fa fa-chevron-up pull-right"></i>
          <i class="fa fa-chevron-down pull-right"></i>
        </button>
      </div>
    </div>
    <div v-bind:class="`collapse ${expanded === 'true' ? 'show' : ''}`" v-bind:id="id" v-bind:data-parent="`#${accordionName}`" v-bind:aria-labelledby="`${id}Header`" >
      <div class="card-body container">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AccordionCard',
  props: {
    title: String,
    id: String,
    accordionName: String,
    expanded: String,
    active: Boolean,
  },
};

</script>

<style scoped>

button[aria-expanded=false] .fa-chevron-up {
  display: none;
}
button[aria-expanded=true] .fa-chevron-down {
  display: none;
}

</style>
