import { firebaseAction } from 'vuexfire';
import firebaseReference from './FirebaseReference';

export default {
  async bindConfigWithInitialize({ dispatch }, payload)
  {
    let exist = true;

    const fbConfigRef = await firebaseReference.configRoot;
    await fbConfigRef.child(payload.endpoint).once('value', (snapshot) => { exist = (snapshot.val() !== null); });
    if (!exist)
    {
      console.log(`${payload.endpoint} does not exist, creating it`);
      await dispatch('updateConfig', payload);
    }
    return dispatch('bindConfig', payload);
  },

  /**
   * @brief
   * This action bind a firebase CONFIG node with a store object
   * @warning The payload parameter must respect the specification defined above
   * @param payload the binded object/node specification, see definition above
   * @return nothing
   */
  bindConfig: firebaseAction(async function callback({ bindFirebaseRef }, { objectName, endpoint })
  {
    const fbConfigRef = firebaseReference.configRoot;
    console.log(`start config listener to : /${endpoint} - bind To : state.${objectName}`);
    return bindFirebaseRef(objectName || '', fbConfigRef.child(endpoint));
  }),

  /**
   * @brief
   * This action bind a firebase DATA node with a a store object
   * @warning The payload parameter must respect the specification defined above
   * @param payload the binded object/node specification, see definition above
   * @return nothing
   */
  bindData: firebaseAction(async function callback({ bindFirebaseRef }, { objectName, endpoint })
  {
    const fbDataRef = firebaseReference.dataRoot;
    console.log(`Start data listener to :/${endpoint} - bind To : state.${objectName}`);
    return bindFirebaseRef(objectName || '', fbDataRef.child(endpoint));
  }),
};
