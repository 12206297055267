<template>
  <div id="Hide" class="background h-100 d-flex justify-content-center align-items-center" v-bind:style="backgroundStyle">
  </div>
</template>

<script>

export default {
  name: 'Hide',
  computed: {
    backgroundStyle() {
      return {
        background: `url("${this.$store.state.HideConfig.backgroundImage}") no-repeat center center/cover fixed`,
        backgroundColor: this.$store.state.HideConfig.backgroundColor.hex8 || this.$store.state.HideConfig.backgroundColor.hex,
      };
    },
  },
};

</script>

<style scoped>

</style>
