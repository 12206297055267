<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" v-on:click="closeSidebarPanel" v-if="isConfigOpen"></div>
    <transition name="slide">
      <div v-if="isConfigOpen">
        <div class="sidebar-panel" >
          <div style="max-height: 93%; overflow-y: scroll; overflow-x: hidden" class="container-fluid">
            <HideLeftBar/>
            <EmojisLeftBar/>
            <AdvancedConfiguration/>
          </div>
          <div style="height: 7%;" class="row d-flex justify-content-center align-items-center mx-0">
            <button v-on:click="updateConfigsOnBase" class="btn btn-primary btn-raised col-6">Sauvegarder</button>
            <p class="small text-muted">Emojis only</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapState } from 'vuex';
import AdvancedConfiguration from '@/components/AdvancedConfiguration.vue';
import AccordionCard from '@/components/AccordionCard.vue';
import HideLeftBar from '../Hide/HideLeftBar.vue';
import EmojisLeftBar from '../Emojis/EmojisLeftBar.vue';

export default {
  name: 'SidePanel',
  methods: {
    closeSidebarPanel() {
      this.$store.commit('toggleConfig');
      console.log();
    },
    updateConfigsOnBase() {
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/HideConfig', object: this.HideConfig });
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/EmojisConfig', object: this.EmojisConfig });
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/customCSS', object: this.customCSS });
      this.saveNotif();
    },
  },
  components: {
    AdvancedConfiguration,
    HideLeftBar,
    EmojisLeftBar,
  },
  computed: {
    ...mapState([
      'isConfigOpen',
      'mode',
      'showEmojis',
      'HideConfig',
      'EmojisConfig',
      'customCSS',
    ]),
  },
  notifications: {
    saveNotif: {
      type: VueNotifications.types.success,
      title: 'Configurations sauvegardées',
      message: 'Les configurations ont bien été sauvegardées !',
    },
  },
};

</script>

<style scoped>

.slide-enter-active,
.slide-leave-active
{
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s
}

.sidebar-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: #FFFFFF;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  width: 20%;
}

</style>
