/**
 * TODO DOC
 */
const EmojisConfig = {
  displayZone: false,
  drawingZone: {
    height: 100,
    width: 100,
    x: 0,
    y: 0,
  },
  drawingMethod: 'svg',
  direction: 'up',
  size: 50,
  scale: 50,
  scaleRandomness: 30,
  speed: 7,
  speedRandomness: 30,
  amplitude: 3,
  amplitudeRandomness: 30,
  frequency: 8,
  frequencyRandomness: 30,
  maxDisplayed: 35,
};

export default EmojisConfig;
