<template>
  <div>
    <div class="row mt-3">
      <form v-on:submit.prevent="" class="col-12">
        <div class="form-group">
          <label class="small font-weight-bold bmd-label-static">Style personnalisé</label>
          <codemirror style="" v-model="customCSS.value" :options="cmOptions"/>
          <button type="button" data-toggle="modal" data-target="#CustomCSSModal" class="mt-1 btn btn-primary btn-raised">Editer le style dans une modale <i class='fas fa-expand-arrows-alt'></i></button>
        </div>
        <div class="form-group">
          <label style="top: 0" class="align-middle small font-weight-bold bmd-label-static">Polices personnalisées ({{Object.keys(GoogleFonts).length}} chargées)</label>
          <button data-toggle="modal" data-target="#AddFontModal" class="btn btn-primary btn-raised h-100">Ajouter ou supprimer des polices</button>
        </div>
        <div class="form-group">
          <label style="top: 0" class="align-middle small font-weight-bold bmd-label-static">Importer / Exporter les configurations</label>
          <div class="row">
            <div class="col-md-6 text-center">
              <button class="btn btn-primary btn-secondary btn-raised" v-on:click="exportConfigs">Exporter</button>
            </div>
            <div class="col-md-6 text-center">
              <button class="btn btn-primary btn-danger btn-raised" v-on:click="importButtonClick">Importer</button>
              <input accept="application/JSON" ref="importHiddenInput" type="file" v-on:change="importConfigs" style="visibility: hidden">
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/theme/darcula.css';
import 'codemirror/addon/hint/show-hint';
import { mapState } from 'vuex';
import VueNotifications from 'vue-notifications';

export default {
  name: 'AdvancedConfiguration',
  components: {
    codemirror,
  },
  computed: {
    ...mapState(['customCSS', 'GoogleFonts']),
  },
  methods: {
    exportConfigs() {
      const jsonData = JSON.stringify(this.$store.getters.getFullConfigObject, null, 4);
      console.log(jsonData);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `config_${this.$store.getters.eventID}_${this.$store.getters.interactionID}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    importButtonClick() {
      this.$refs.importHiddenInput.click();
    },
    importConfigs(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          this.$store.commit('setConfiguration', object);
          this.importNotifSuccess();
        } catch (ex)
        {
          this.importNotifError();
          console.log(`Invalid config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
    updateAdvConfigOnBase() {
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/customCSS', object: this.customCSS }).then(this.saveNotif());
    },
  },
  data() {
    return {
      cmOptions: {
        tabSize: 4,
        mode: 'text/css',
        theme: 'darcula',
        lineNumbers: true,
        line: true,
        autofocus: true,
        addons: 'show-hint',
      },
    };
  },
  notifications: {
    saveNotif: {
      type: VueNotifications.types.success,
      title: 'Configuration sauvegardée !',
      message: 'La configuration avancé a bien été sauvegardée !',
    },
    importNotifSuccess: {
      type: VueNotifications.types.success,
      title: 'Configuration importée !',
      message: 'La nouvelle configuration a bien été importé, pensez à sauvegarder',
    },
    importNotifError: {
      type: VueNotifications.types.error,
      title: 'Configuration invalide',
      message: 'Import impossible, le fichier est inccorecte',
    },
  },
};

</script>

<style>

</style>
