<template>
  <div id="EmojisDrawingZone" class="background" v-bind:style="drawingZoneStyle">
  </div>
</template>

<script>

import Two from 'two.js';
import { mapState } from 'vuex';

const Chance = require('chance');

export default {
  name: 'Emojis.vue',
  data() {
    return {
      two: null,
      chance: new Chance(),
      circles: [],
      EmojisTextures: {},
    };
  },
  computed: {
    ...mapState(['EmojisConfig', 'showEmojis']),
    drawingZoneStyle() {
      return {
        border: (this.EmojisConfig.displayZone) ? 'solid #FAA725' : '',
        position: 'Fixed',
        left: `${this.EmojisConfig.drawingZone.x}%`,
        top: `${this.EmojisConfig.drawingZone.y}%`,
        width: `${this.EmojisConfig.drawingZone.width}%`,
        height: `${this.EmojisConfig.drawingZone.height}%`,
      };
    },
  },
  updated()
  {
    window.addEventListener('resize', this.onResize);
    this.createTwoContext();
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted()
  {
    this.$store.watch((_state) => _state.showEmojis.customEmojis, () => {
      this.loadEmojisTextures();
    });
    this.$store.watch((_state) => _state.Reactions, (newVal, oldVal) => {
      for (let i = 0; i < 6; i = i + 1)
      {
        const accessor = `react_${i}`;
        if ((newVal[accessor] || 0) > (oldVal[accessor] || 0))
        {
          const dist = (newVal[accessor] || 0) - (oldVal[accessor] || 0);
          for (let y = 0; y < dist; y = y + 1)
            this.addEmoji(this.EmojisTextures[i]);
        }
      }
    });
    this.createTwoContext();
  },
  methods: {
    onResize()
    {
      this.createTwoContext();
    },
    createTwoContext()
    {
      const elem = document.getElementById('EmojisDrawingZone');
      elem.innerHTML = '';
      const params = {
        width: elem.offsetWidth,
        height: elem.offsetHeight,
        type: 'svg',
      };
      params.type = this.EmojisConfig.drawingMethod === 'svg' ? Two.Types.svg : params.type;
      params.type = this.EmojisConfig.drawingMethod === 'webGl' ? Two.Types.webgl : params.type;
      params.type = this.EmojisConfig.drawingMethod === 'canvas' ? Two.Types.canvas : params.type;
      if (this.two)
      {
        this.two.unbind('update');
        delete this.two;
      }

      this.two = new Two(params).appendTo(elem);
      console.log(`Created moodboard with mode : ${params.type}`);
      this.loadEmojisTextures();
      this.two.bind('update', (count, delta) => {
        this.circles.forEach((cr) => {
          cr.killed = this.isEmojiDead(cr);
          if (!cr.killed)
          {
            cr.m_angle += ((cr.movingParams.inverted) ? cr.movingParams.frequency : (cr.movingParams.frequency * -1)) * (delta / 10);
            if (this.EmojisConfig.direction === 'right')
            {
              cr.translation.x += cr.movingParams.translationSpeed * (delta / 10);
              cr.translation.y += (Math.sin(cr.m_angle) * cr.movingParams.amplitude) * (delta / 10);
            }
            else if (this.EmojisConfig.direction === 'left')
            {
              cr.translation.x -= cr.movingParams.translationSpeed * (delta / 10);
              cr.translation.y += (Math.sin(cr.m_angle) * cr.movingParams.amplitude) * (delta / 10);
            }
            else if (this.EmojisConfig.direction === 'down')
            {
              cr.translation.y += cr.movingParams.translationSpeed * (delta / 10);
              cr.translation.x += (Math.sin(cr.m_angle) * cr.movingParams.amplitude) * (delta / 10);
            }
            else if (this.EmojisConfig.direction === 'up')
            {
              cr.translation.y -= cr.movingParams.translationSpeed * (delta / 10);
              cr.translation.x += (Math.sin(cr.m_angle) * cr.movingParams.amplitude) * (delta / 10);
            }
          }
        });
        this.circles.forEach((cr) => {
          if (cr.killed)
          {
            this.circles.splice(this.circles.indexOf(cr), 1);
            this.two.remove(cr);
          }
        });
      }).play();
      window.removeEventListener('keypress', this.onKeyPressed);
      window.addEventListener('keypress', this.onKeyPressed);
    },
    onKeyPressed(e)
    {
      for (let i = 0; i < 6; i = i + 1)
        if (e.key === `${i + 1}`)
          this.addEmoji(this.EmojisTextures[i]);
    },
    loadEmojisTextures()
    {
      for (let i = 0; i < 6; i = i + 1)
        if (this.showEmojis.customEmojis && this.showEmojis.customEmojis[i] && this.showEmojis.customEmojis[i].includes('https://cdn.captag.events')) {
          const url = new URL(this.showEmojis.customEmojis[i]);
          url.search = '';
          url.searchParams.append('func', 'cover');
          url.searchParams.append('w', '100');
          url.searchParams.append('h', '100');
          this.showEmojis.customEmojis[i] = url.toString();
        }
      for (let i = 0; i < 6; i = i + 1)
        this.EmojisTextures[i] = new Two.Texture((this.showEmojis.customEmojis && this.showEmojis.customEmojis.length >= i) ? this.showEmojis.customEmojis[i] : `assets/reaction_${i + 1}.png`);
    },
    addEmoji(Texture)
    {
      if (!this.two || this.circles.length >= this.EmojisConfig.maxDisplayed)
        return;
      const texture = Texture; // new Two.Texture(EmojiURL)
      const circle = this.two.makeCircle(-70, 0, parseInt(this.EmojisConfig.size, 10));
      circle.fill = texture;
      circle.translation.set(...this.setStartPosition());
      const delta = this.EmojisConfig.scaleRandomness / 100;
      circle.scale = this.chance.integer({ min: this.EmojisConfig.scale * (1 - delta), max: this.EmojisConfig.scale * (1 + delta) }) / 100;
      circle.m_angle = 0;
      circle.movingParams = this.randomMovingParameters();
      circle.killed = false;
      circle.noStroke();
      this.circles.push(circle);
    },
    isEmojiDead(emoji)
    {
      if (this.EmojisConfig.direction === 'right' && emoji.translation.x >= this.two.width)
      {
        return true;
      }
      if (this.EmojisConfig.direction === 'left' && emoji.translation.x <= 0)
        return true;
      if (this.EmojisConfig.direction === 'down' && emoji.translation.y >= this.two.height)
        return true;
      if (this.EmojisConfig.direction === 'up' && emoji.translation.y <= 0)
        return true;
      return false;
    },
    setStartPosition()
    {
      let x = 0;
      let y = 0;
      if (this.EmojisConfig.direction === 'down')
      {
        x = this.chance.integer({ min: 0, max: this.two.width });
        y = 0;
      }
      if (this.EmojisConfig.direction === 'up')
      {
        x = this.chance.integer({ min: 0, max: this.two.width });
        y = this.two.height;
      }
      if (this.EmojisConfig.direction === 'right')
      {
        x = 0;
        y = this.chance.integer({ min: 0, max: this.two.height });
      }
      if (this.EmojisConfig.direction === 'left')
      {
        x = this.two.width + 30;
        y = this.chance.integer({ min: 80, max: this.two.height });
      }
      return [x, y];
    },
    randomMovingParameters()
    {
      const movingParams = {};
      let delta = this.EmojisConfig.speedRandomness / 100;
      movingParams.translationSpeed = this.chance.integer({ min: (this.EmojisConfig.speed > 1 ? this.EmojisConfig.speed : 1) * (1 - delta), max: this.EmojisConfig.speed * (1 + delta) });
      delta = this.EmojisConfig.amplitudeRandomness / 100;
      movingParams.amplitude = this.chance.integer({ min: this.EmojisConfig.amplitude * (1 - delta), max: this.EmojisConfig.amplitude * (1 + delta) });
      delta = this.EmojisConfig.frequencyRandomness / 100;
      movingParams.frequency = this.chance.integer({ min: this.EmojisConfig.frequency * (1 - delta), max: this.EmojisConfig.frequency * (1 + delta) }) / 100;
      movingParams.inverted = this.chance.bool(); // is sin inverted ? (aka. is the sin angle going backward)
      return movingParams;
    },
  },
};

</script>

<style scoped>

</style>
