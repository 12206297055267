import FirebaseActions from './FirebaseActions';
import FirebaseBindings from './FirebaseBindings';
import { fbInit } from './FirebaseReference';

/**
 * @brief Store functions, mostly related to firebase
 * Most of the action will take a payload argument, it must be formmated like this :
 *  payload: {
 *   endpoint: '',    // the node name you want to bind
 *   objectName: '',  // Name of the object you want to bind, must be available in the state (optional in the action 'updateConfig')
 *   object: {},      // the bounded object (optional in the action 'bindConfig/Data')
 *  },
 */
const actions = {
  ...FirebaseActions,
  ...FirebaseBindings,

  /**
   * @brief
   * Load the query parameters in the URL
   *  - path : the firebase path for the interaction
   *  - tokenID : Captag TokenID
   * on success this function will dispatch the action 'connectToFirebase'
   * @param none
   * @return nothing
   */
  async loadUrlParams({ commit, dispatch, state })
  {
    const searchParams = new URLSearchParams(window.location.search);
    const claims = await fbInit(searchParams.get('tk') || '');
    if (!claims)
    {
      console.log('Error : missing parameter tokenID');
      commit('setMode', 'BadConfig');
      commit('setCommand', '');
      return;
    }

    commit('showConfig', claims.showConfig !== false);

    dispatch('bindConfigWithInitialize', { endpoint: 'screenConfig/EmojisConfig', objectName: 'EmojisConfig', object: state.EmojisConfig });
    dispatch('bindConfigWithInitialize', { endpoint: 'screenConfig/HideConfig', objectName: 'HideConfig', object: state.HideConfig });
    dispatch('bindData', { endpoint: 'reactionsCount', objectName: 'Reactions', object: state.Reactions });
    dispatch('bindConfig', { endpoint: 'screenConfig/fonts', objectName: 'GoogleFonts', object: state.GoogleFonts });
    dispatch('bindConfig', { endpoint: 'screenConfig/customCSS', objectName: 'customCSS', object: state.customCSS });
    dispatch('bindConfig', { endpoint: 'config/emojis', objectName: 'showEmojis', object: state.showEmojis });

    commit('toggleLoading', false);
  },
};

export default actions;
