<template>
  <div>
    <a data-toggle="collapse" v-bind:href="'#picker_' + this._uid">
      <div class="row">
        <label style="top: 0px" class="align-middle small font-weight-bold bmd-label-static col">{{ Label }}</label>
        <div class="col-4" v-bind:style="colorPreview"></div>
        <i class="col-2 fas fa-angle-down"></i>
      </div>
    </a>
    <div class="collapse mt-3 text-center"  v-bind:id="'picker_' + this._uid">
      <div class="row d-flex justify-content-center">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InputColorPicker',
  props: {
    model: Object,
    Label: String,
  },
  computed: {
    colorPreview() {
      return {
        backgroundColor: this.model.hex,
      };
    },
  },
};

</script>

<style scoped>

</style>
