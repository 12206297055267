<template>
  <div v-on:mouseenter='showButton' v-on:mouseleave='hideButton'>
    <div v-bind:style='visibilityStyle' class='row' >
      <div class='col-md-4'>
        <button v-on:click='toggleFullscreen' class='btn btn-raised btn-primary'>
          <i class='fas fa-expand'></i> Plein écran
        </button>
      </div>
      <div class='col-md-4'>
        <button v-on:click='parametersClick' class='btn btn-raised btn-primary'><i class='fas fa-cogs'></i> Configuration
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RightsButtons',
  data() {
    return {
      showButtons: false,
    };
  },
  computed: {
    visibilityStyle() {
      return { visibility: this.showButtons ? 'visible' : 'hidden' };
    },
  },
  methods: {
    parametersClick() {
      this.$store.commit('toggleConfig');
    },
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    showButton() {
      this.showButtons = true;
    },
    hideButton() {
      this.showButtons = false;
    },
  },
};

</script>

<style scoped>

</style>
