<template>
  <div id='app' class="h-100 container-fluid pl-0 pr-0">
    <div v-if="isLoading" class="h-100">
      <LoadingPage />
    </div>
    <missing-configuration v-else-if="isError"/>
    <div v-else class="h-100" >
      <Hide/>
      <Emojis/>
    </div>
    <rights-buttons v-if="showConfig" id="rightButtons"></rights-buttons>
    <SidePanel v-if="showConfig" class="leftPanel" title="Vote Live"/>
    <CustomCSSEditorModal v-if="showConfig"/>
    <AddFontModal v-if="showConfig"/>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import RightsButtons from '@/components/RightsButtons.vue';
import CustomCSSEditorModal from '@/components/CustomCSSEditorModal.vue';
import AddFontModal from '@/components/AddFontModal.vue';
import SidePanel from '@/components/SidePanel.vue';
import LoadingPage from '@/components/LoadingPage.vue';
import Hide from './Hide/Hide.vue';
import Emojis from './Emojis/Emojis.vue';
import MissingConfiguration from './MissingConfiguration.vue';

export default {
  components: {
    MissingConfiguration,
    LoadingPage,
    AddFontModal,
    CustomCSSEditorModal,
    RightsButtons,
    Hide,
    SidePanel,
    Emojis,
  },
  computed: {
    ...mapState(['showConfig', 'isLoading', 'isError']),
  },

};

</script>

<style>

#CustomCSSModal .CodeMirror {
  height: 100%;
}

html, body{
  height: 100%;
}

#rightButtons {
  position: fixed;
  right: 30px;
  top: 30px;
}

.separator{
  border: none;
  border-top: 3px solid #333;
}

.leftPanel {
  position: fixed;
  top: 0px;
  left: 0px;
}

</style>
