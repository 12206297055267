import HideConfig from '../Hide/HideConfig';
import EmojisConfig from '../Emojis/EmojisConfig';

const state = {
  showConfig: false,
  isLoading: true,
  isError: false,
  // is the left menu is open
  isConfigOpen: false,
  // True if the emojis are enabled
  showEmojis: {
    customEmojis: [],
    screen: false,
  },
  // Hide configuration Object
  HideConfig,
  // Emojis configuration Object
  EmojisConfig,
  // User custom style
  customCSS: {
    value: '/* ajouter votre CSS ici */\n',
  },
  // Reaction (Mood board) lists {userID: {reactionID: number}}
  Reactions: {},
  // Google font imported to the project
  GoogleFonts: {},
};

export default state;
