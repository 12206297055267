<template>
  <div class="row mt-3">
    <form v-on:submit.prevent="" class="col-12">
      <div class="form-group">
        <InputColorPicker v-on:submit.prevent Label="Couleur du fond" v-bind:model="HideConfig.backgroundColor">
          <sketch v-model="HideConfig.backgroundColor"/>
        </InputColorPicker>
      </div>
      <div class="form-group">
        <InputImageUpload title="Image de fond" v-bind:target="HideConfig" targetKey="backgroundImage"/>
      </div>
    </form>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import VueColor from 'vue-color';
import InputColorPicker from '@/components/InputColorPicker.vue';
import InputImageUpload from '@/components/InputImageUpload.vue';

export default {
  name: 'HideLeftBar',
  computed: {
    ...mapState(['HideConfig']),
  },
  components: {
    InputImageUpload,
    InputColorPicker,
    Sketch: VueColor.Chrome,
  },
};

</script>

<style scoped>

</style>
