<template>
    <div class='form-row'>
      <div class='col-10'>
        <label class='small font-weight-bold bmd-label-static' for='inputImage'>{{ title }}</label>
        <input id='inputImage' v-model='target[targetKey]' type='text' class='form-control' placeholder='https://...'>
      </div>
      <button class='col-2 btn btn-info btn-raised my-3' v-on:click='uploadImage'><i class='fas fa-upload'></i></button>
    </div>
</template>

<script>

import VueNotifications from 'vue-notifications';

export default {
  name: 'InputImageUpload',
  props: {
    title: String,
    target: Object,
    targetKey: String,
  },
  methods: {
    uploadImage()
    {
      const { eventID, interactionID } = this.$store.getters;
      window.cptgUploader.init('identifiantUnique', `${eventID}/interactions/`, {
        apiKey: 'f2Ran1l9K1kJCAUEPWj5',
        sources: ['local'],
        lang: this.language || 'fr',
        theme: 'light',
        useEditor: false,
        forceSquareCrop: false,
        maxFileSize: 20,
      }, (result) => {
        this.uploadSuccess();
        this.$emit('change', result.originalURL || '');
        this.target[this.targetKey] = result.originalURL || '';
      }).open();
    },
  },
  notifications: {
    uploadSuccess: {
      type: VueNotifications.types.success,
      title: 'Image uploadé !',
      message: 'Image uploadé avec succès.',
    },
    uploadError: {
      type: VueNotifications.types.error,
      title: 'Erreur',
      message: 'Une erreur est survenue lors de l\'envois de l\'image.',
    },
  },
};

</script>

<style scoped>

</style>
