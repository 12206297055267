<template>
  <!-- Modal -->
  <div id="CustomCSSModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="CustomCSSModal" aria-hidden="true">
    <div class="h-75 modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="h-100 modal-content" >
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Éditeur de CSS personnalisé</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="h-100 modal-body">
          <codemirror class="h-100" v-model="customCSS.value" :options="cmOptions"/>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary btn-raised" v-on:click="updateAdvConfigOnBase" >Sauvegarder</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/theme/darcula.css';
import 'codemirror/addon/hint/show-hint';
import VueNotifications from 'vue-notifications';

export default {
  name: 'CustomCSSEditorModal',
  components: {
    codemirror,
  },
  methods: {
    updateAdvConfigOnBase() {
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/customCSS', object: this.customCSS }).then(this.saveCSSNotif());
    },
  },
  computed: {
    ...mapState(['customCSS']),
  },
  data() {
    return {
      cmOptions: {
        tabSize: 4,
        mode: 'text/css',
        theme: 'darcula',
        lineNumbers: true,
        line: true,
        autofocus: true,
        addons: 'show-hint',
      },
    };
  },
  notifications: {
    saveCSSNotif: {
      type: VueNotifications.types.success,
      title: 'Style sauvegardée !',
      message: 'Votre style personnalisé à bien été sauvegardée !',
    },
  },
};

</script>

<style scoped>

</style>
