import { vuexfireMutations } from 'vuexfire';

const mutations = {
  ...vuexfireMutations,
  toggleConfig(state)
  {
    state.isConfigOpen = !state.isConfigOpen;
  },
  showConfig(state, value)
  {
    state.showConfig = value;
  },
  toggleLoading(state, value)
  {
    state.isLoading = value;
  },
  setCaptagApiToken(state, token)
  {
    state.captagApiToken = token;
  },
  setConfiguration(state, newConfig)
  {
    if (newConfig.HideConfig)
      state.HideConfig = newConfig.HideConfig;
    if (newConfig.EmojisConfig)
      state.EmojisConfig = newConfig.EmojisConfig;
    if (newConfig.customCSS)
      state.customCSS = newConfig.customCSS;
    if (newConfig.GoogleFonts)
      state.GoogleFonts = newConfig.GoogleFonts;
  },
};

export default mutations;
