// import firebase from 'firebase/app';
//
// require('firebase/auth');
// require('firebase/database');
//
// // Config node path
// export const interactionConfigPath = 'Storage/interactions/interactions_configs';
//
// // Data node path
// export const interactionDatasPath = 'Storage/interactions/interactions_datas';
//
// // Firebase database information
// const firebaseConfig = {
//   apiKey: 'AIzaSyBfONQeE8VLk1zWzJ5PDzAZEA1a7gl9Y2k',
//   authDomain: 'captag-events.firebaseapp.com',
//   databaseURL: 'https://captag-events-2.europe-west1.firebasedatabase.app/',
//   projectId: 'captag-events',
//   storageBucket: 'captag-events.appspot.com',
//   messagingSenderId: '418843808210',
//   appId: '1:418843808210:web:52ea9222621c9d1c76f80b',
//   measurementId: 'G-JFHBLW7WVF',
// };
//
// let fb;

// export async function firebaseReference(token, dispatch = null) {
//   if (fb === undefined)
//   {
//     const firebaseApp = firebase.initializeApp(firebaseConfig);
//     try {
//       const credentials = await firebaseApp.auth().signInWithCustomToken(token);
//       fb = firebaseApp.database();
//       const { claims } = (await credentials.user.getIdTokenResult(true));
//       claims.tokenID = await credentials.user.getIdToken(true);
//       if (dispatch) {
//         dispatch('loadTokenParams', claims);
//       }
//       console.log('Logged in !');
//     } catch (error) {
//       console.log('Can\'t log in!');
//       fb = null;
//     }
//   }
//   return fb;
// }

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// Firebase database information
const firebaseDB1Config = {
  apiKey: 'AIzaSyBfONQeE8VLk1zWzJ5PDzAZEA1a7gl9Y2k',
  authDomain: 'captag-events.firebaseapp.com',
  databaseURL: 'https://captag-events-2.europe-west1.firebasedatabase.app/',
  projectId: 'captag-events',
  storageBucket: 'captag-events.appspot.com',
  messagingSenderId: '418843808210',
  appId: '1:418843808210:web:52ea9222621c9d1c76f80b',
  measurementId: 'G-JFHBLW7WVF',
};

class FbReference {
  constructor()
  {
    this.fbCustomToken = '';
    this.apiToken = '';
    this.fb = undefined;
    this.userCredentials = undefined;
    this.eventID = '';
    this.animID = '';
  }

  // eslint-disable-next-line class-methods-use-this
  async fetchTokenFromAPI(queryToken)
  {
    console.log('Requesting database tokens...');
    const fetchOption = {
      method: 'GET',
      headers: {
        authorization: `Bearer ${queryToken}`,
      },
    };
    const rep = await fetch('https://europe-west1-captag-events.cloudfunctions.net/ems/generateTokens', fetchOption);
    if (!rep.ok)
    {
      console.error('Error while querying QPI generateToken');
      return undefined;
    }
    console.log('...Done.');
    return rep.json();
  }

  async connectToFirebase()
  {
    if (this.fb && this.userCredentials)
      return (await this.userCredentials.user.getIdTokenResult(true)).claims || undefined;
    let out;
    const firebaseApp = firebase.initializeApp(firebaseDB1Config);
    try {
      this.userCredentials = await firebaseApp.auth().signInWithCustomToken(this.fbCustomToken);
      this.fb = firebaseApp.database();
      const { claims } = (await this.userCredentials.user.getIdTokenResult(true));
      if (claims && claims.eventID && claims.animID)
      {
        this.eventID = claims.eventID;
        this.animID = claims.animID;
      }
      out = claims;
      console.log('Logged in !');
    } catch (error) {
      console.log('Can\'t log in!', error);
      this.fb = undefined;
      return undefined;
    }
    return out;
  }

  async init(queryToken)
  {
    const tokens = await this.fetchTokenFromAPI(queryToken);
    if (!tokens)
      return undefined;
    this.fbCustomToken = tokens.customToken || '';
    this.apiToken = tokens.apiToken || '';
    return this.connectToFirebase();
  }

  get ref()
  {
    return this.fb || undefined;
  }

  get configRoot()
  {
    return this.fb.ref('Storage/interactions/interactions_configs').child(this.eventID).child(this.animID);
  }

  get dataRoot()
  {
    return this.fb.ref('Storage/interactions/interactions_datas').child(this.eventID).child(this.animID);
  }

  async fetchAPI(url, { query = {}, body = {}, method = 'GET' } = {})
  {
    const fetchOption = {
      method,
      headers: { authorization: `Bearer ${this.apiToken}` },
    };
    if (Object.keys(body).length > 0)
    {
      fetchOption['content-type'] = 'application/json';
      fetchOption.body = JSON.stringify(body);
    }
    let fullUrl = url;
    if (Object.keys(query).length > 0)
    {
      fullUrl = `${fullUrl}${url.includes('?') ? '&' : '?'}`;
      Object.entries(query).forEach(([key, value]) => {
        fullUrl = `${fullUrl}${key}=${value}&`;
      });
      fullUrl.splice(-1, 1);
    }
    const rep = await fetch(fullUrl, fetchOption);
    const repBody = await rep.json();
    if (!rep.ok)
      return [undefined, repBody ? repBody.message || '' : ''];
    return [repBody, ''];
  }

  get rootDatabaseUrl()
  {
    return `${firebaseDB1Config.databaseURL}/animations/${this.eventID || ''}/${this.animID || ''}`;
  }
}

const fbReferenceClass = new FbReference();

export async function fbInit(queryToken)
{
  return fbReferenceClass ? fbReferenceClass.init(queryToken) : undefined;
}

export const fbTimestamp = firebase.database.ServerValue.TIMESTAMP;

export default fbReferenceClass;
